<template>
  <div class="container-main modal-theme-1">
    <template v-if="type_active !== 'not-have-permission'">
      <div class="modal-header border-0">
        <ul class="nav text-center" v-if="!['login-token', 'login-ba'].includes(type_active)">
          <li class="nav-item">
            <a
                class="nav-link"
                :class="{ active: type_active === 'login' }"
                href="#"
                @click.prevent="changeType('login')"
            >{{ $t('ct-sign-in') }}</a
            >
          </li>
          <li class="nav-item" v-if="!config.hide_register_btn">
            <a
                class="nav-link"
                :class="{ active: type_active === 'register' }"
                href="#"
                @click.prevent="changeType('register')"
            >{{ $t('ct-register') }}</a
            >
          </li>
          <li class="nav-item">
            <a
                class="nav-link"
                :class="{ active: type_active === 'remember' }"
                @click.prevent="changeType('remember')"
            >{{ $t('ct-remember') }}</a
            >
          </li>
        </ul>
        <ul class="nav text-center" v-if="type_active === 'login-token'">
          <li class="nav-item">
            <a
                class="nav-link"
                :class="{ active: type_active === 'login-token' }"
                href="#"
                @click.prevent=""
            >{{ $t('ct-access-with-token') }}</a
            >
          </li>
        </ul>

        <ul class="nav text-center" v-else-if="type_active === 'login-ba'">
          <li class="nav-item">
            <a
                class="nav-link"
                :class="{ active: type_active === 'login-ba' }"
                href="#"
                @click.prevent=""
            >{{ $t('ct-access-ba-credentials') }}</a
            >
          </li>
        </ul>
      </div>
      <div class="tab-content modal-body border-0">
        <div class="tab-pane fade" :class="{ 'show active': type_active === 'register' }">
          <register
              v-if="type_active === 'register'"
              :areas_register="areas_register"
              @change="changeType"
          ></register>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': type_active === 'remember' }">
          <remember-password v-if="type_active === 'remember'" @change="changeType"></remember-password>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': type_active === 'login' }">
          <login v-if="type_active === 'login'" @change="changeType"></login>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': type_active === 'login-token' }">
          <login-token v-if="type_active === 'login-token'" @change="changeType"></login-token>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': type_active === 'login-ba' }">
          <login-builderall v-if="type_active === 'login-ba'" @change="changeType"></login-builderall>
        </div>
      </div>
    </template>
    <template v-if="type_active === 'not-have-permission'">
      <not-have-permission></not-have-permission>
    </template>
  </div>
</template>

<script>
import {mixinRestrictAreaConfig, mixinRestrictAreaForm} from "@/js/mixins/restrictAreaForm";

export default {
    name: 'ThreeTabsPopup',
    mixins: [mixinRestrictAreaConfig, mixinRestrictAreaForm],
};
</script>
